import React from 'react'
import { css, cx } from '@linaria/core'
import { Button } from '@/UI'
import { desktopSmallMedia } from '@/lib/theme'
import { useGoToContacts } from '../../hooks/useGoToContacts'
import { useCloseNavHandler } from '@/blocks/layout/partials/header/components'

type ButtonVisibleMediaType = 'mobile' | 'desktop'

interface Props {
  className?: string
  showOn?: ButtonVisibleMediaType
}

export const ButtonContactCommon = ({ className, showOn }: Props) => {
  const goToContacts = useGoToContacts()
  const closeNav = useCloseNavHandler()

  const clickHandler = () => {
    goToContacts()
    closeNav()
  }

  return (
    <Button
      rippleDuration="0.3s"
      onClick={clickHandler}
      type="dark"
      size="small"
      className={cx(
        className,
        showOn === 'mobile' && VisibleOnMobile,
        showOn === 'desktop' && VisibleOnDesktop,
      )}
    >
      Contact
    </Button>
  )
}

const VisibleOnDesktop = css`
  display: none;

  ${desktopSmallMedia} {
    display: flex;
  }
`

const VisibleOnMobile = css`
  display: flex;

  ${desktopSmallMedia} {
    display: none;
  }
`
